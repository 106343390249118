<template>
  <base-material-card icon="mdi-clipboard-text" :title="$t('menu.listing.list')" class="px-5 py-3">
    <base-add-button
      :permissions="permissions"
      :basePath="basePath"
      :title="$t('listing.add')"
      :params="addBtnParams"
    >
    </base-add-button>
    <div class="d-flex align-items-center">
      <div class="mr-6">
        <div class="mt-2">{{ $t('listing.totalVisit') }}: {{ totalVisit }}</div>
        <div class="mt-2">{{ $t('listing.totalImpression') }}: {{ totalImpression }}</div>
      </div>
      <div>
        <div class="mt-2">{{ $t('listing.totalWaLead') }}: {{ totalWaLead }}</div>
        <div class="mt-2">{{ $t('listing.totalContact') }}: {{ totalContact }}</div>
      </div>
    </div>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="text-center primary--text">
            {{ $t('general.actions') }}
          </th>
          <th class="text-center primary--text">
            {{ $t('general.openDetail') }}
          </th>
          <!--          <th class="primary&#45;&#45;text">-->
          <!--            ID-->
          <!--          </th>-->
          <th class="primary--text">
            {{ $t('general.listingNumber') }}
          </th>
          <th class="primary--text">
            {{ $t('listing.byAdmin') }}
          </th>
          <th class="primary--text">
            {{ $t('listing.listingName') }}
          </th>
          <th class="primary--text">
            {{ $t('listing.propertyType') }}
          </th>
          <th class="primary--text">
            {{ $t('listing.status') }}
          </th>
          <th class="primary--text">
            {{ $t('listing.price') }}
          </th>
          <th class="primary--text">
            {{ $t('listing.city') }}
          </th>
          <th class="primary--text">
            {{ $t('listing.updatedBy') }}
          </th>
          <th class="primary--text">
            {{ $t('listing.updatedAt') }}
          </th>
          <th class="primary--text">
            {{ $t('agentMember.visitAmount') }}
          </th>
          <th class="primary--text">
            {{ $t('agentMember.impressionAmount') }}
          </th>
          <th class="primary--text">
            {{ $t('agentMember.waLeadsAmount') }}
          </th>
          <th class="primary--text">
            {{ $t('agentMember.contactAmount') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in items" :key="item.uuid">
          <td>
            <div class="d-flex justify-center align-center">
              <base-production-detail-button
                :link="item.links ? websiteUrl + item.links.detail : ''"
              ></base-production-detail-button>
              <base-edit-button
                :permissions="intersect(permissions, item.allowed_actions)"
                :basePath="basePath"
                :id="item.uuid"
                :params="addBtnParams"
              ></base-edit-button>
              <base-delete-button
                :permissions="intersect(permissions, item.allowed_actions)"
                :id="item.uuid"
                :showIcon="true"
                @deleteAction="deleteAction"
              ></base-delete-button>
            </div>
          </td>
          <td>
            <base-detail-button
              :permissions="permissions"
              :basePath="basePath"
              :id="item.uuid"
              style="text-align: center !important"
            ></base-detail-button>
          </td>
          <!--          <td>{{ index + 1 }}</td>-->
          <td>
            <div class="listing--number" @click="copyToClipboard(item.listing_number)">
              <div>
                {{ item.listing_number }}
              </div>
              <div>
                <v-icon>mdi-content-copy</v-icon>
              </div>
            </div>
            <v-snackbar v-model="openSnackbar">
              {{ $t('general.clipboard') }}
            </v-snackbar>
          </td>
          <td>{{ item.by_admin ? $t('general.yes') : $t('general.no') }}</td>
          <td>{{ item.title }}</td>
          <td>{{ item.property_type }}</td>
          <td>{{ item.status }}</td>
          <td>{{ currencyFormat(item.price_num) }}</td>
          <td>{{ item.city }}</td>
          <td>{{ item.updated_by ? item.updated_by.name : '' }}</td>
          <td style="min-width: 200px">
            {{ item.updated_at ? format(new Date(item.updated_at), 'dd MMMM yyyy HH:mm') : '' }}
          </td>
          <td>{{ item.visit_amount }}</td>
          <td>{{ item.impression_amount }}</td>
          <td>{{ item.wa_leads_amount }}</td>
          <td>{{ item.contact_amount }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

const Pagination = () => import('@/components/utils/fractal-pagination');
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: [
    'items',
    'meta',
    'permissions',
    'agentUuid',
    'totalVisit',
    'totalImpression',
    'totalWaLead',
    'totalContact',
  ],
  components: {
    Pagination,
  },
  data: () => ({
    format,
    openSnackbar: false,
  }),
  computed: {
    ...mapState({
      basePath: (state) => state.listing.basePath,
      websiteUrl: (state) => state.global.websiteUrl,
    }),
    addBtnParams() {
      if (this.agentUuid) {
        return {
          agent_uuid: this.agentUuid,
        };
      } else {
        return {};
      }
    },
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    async deleteAction(uuid) {
      console.log('DELETE LISTING UUID: ', uuid);
      try {
        await this.$store.dispatch('listing/delete', uuid);
        await this.$store.dispatch('listing/removeListingListByUuid', uuid);
        this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('listing.delete.successMsg'),
          'success',
        );
        await this.$store.dispatch('listing/getInitData', this.$route.query);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.openSnackbar = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.listing--number {
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: $font-sm;
  font-family: Poppins-SemiBold;
  color: $color-text-gray-2;
  cursor: pointer;

  .v-icon {
    color: $color-text-gray-2;
    font-size: 16px;
    margin-left: 4px;
  }
}
</style>
